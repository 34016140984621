<template>
  <div class="genetec-settings">
    <div v-if="!this.loading && !hasLicense" class="genetec-overlay">
      <div class="license_not_found">{{ $tf('license_not_found') }}</div>
      <div class="contact_admin_imv">{{ $tf('contact_admin_imv') }}</div>
    </div>
    <div class="content-header">
      <div>
        <h2>{{ $tfo('genetec_settings') }}</h2>
        <h3 :class="state.items.configured ? 'success' : 'failed'" style="opacity: 0.5; margin-top: -1em; margin-bottom: 2em">
          {{ $tfo('state') }}: {{ state.items.configured ? $tfo('configured') : '-' }}
        </h3>
      </div>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane :label="$tfo('config')" name="config">
        <h3>{{ $tfo('server') }}</h3>
        <el-form
          :size="$vars.sizes.form"
          :label-position="$vars.forms.label_position"
          :labelWidth="$vars.forms.label_width"
          v-loading="loading || state.loading"
          :name="$options.name + '-config-form'"
        >
          <el-form-item :label="$tfo('host')">
            <el-input name="host" v-model="state.items.host" />
          </el-form-item>
          <el-form-item :label="$tfo('port')">
            <el-input name="port" v-model="state.items.port" />
          </el-form-item>
          <el-form-item :label="$tfo('user')">
            <el-input name="user" v-model="state.items.user" />
          </el-form-item>
          <el-form-item :label="$tfo('password')">
            <el-input name="password" type="password" v-model="state.items.password" />
          </el-form-item>
          <el-form-item :label="$tfo('base_uri')">
            <el-input name="base-uri" v-model="state.items.base_uri" />
          </el-form-item>
          <h3>{{ $tfo('media') }}</h3>
          <el-form-item :label="$tfo('media | host')">
            <el-input name="media-host" v-model="state.items.media_host" />
          </el-form-item>
          <el-form-item :label="$tfo('media | port')">
            <el-input name="media-port" v-model="state.items.media_port" />
          </el-form-item>
          <el-form-item :label="$tfo('user')">
            <el-input name="media-user" v-model="state.items.media_user" />
          </el-form-item>
          <el-form-item :label="$tfo('password')">
            <el-input name="media-password" type="password" v-model="state.items.media_password" />
          </el-form-item>
          <h3>{{ $tfo('ids') }}</h3>
          <el-form-item :label="'Alarm id'">
            <el-input name="alarm-id" v-model="state.items.alarm_id" />
          </el-form-item>
          <el-form-item>
            <el-button name="save-btn" type="primary" @click="save">{{ $tf('common.save') }}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane :label="$tfo('cameras')" name="cameras" :disabled="!state.items.configured">
        <el-form labelWidth="180px" v-loading="state.loading" :name="$options.name + '-cameras-form'">
          <h3>{{ $tfo('import | cameras,,1') }}</h3>
          <el-form-item>
            <el-button name="import-btn" type="primary" @click="importCameras">{{ $tf('common.import') }}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'genetec',
  data: function () {
    return {
      loading: false,
      configured: false,
      activeTab: 'config'
    };
  },
  computed: {
    state() {
      return this.$store.state.genetec_config;
    },
    hasLicense() {
      return findGenetecLicense(this.$store.state.license).value;
    }
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch('get_genetec_config');
      await this.fetchLicenseStatusIfRequired();
    } catch (e) {
      this.handleActionError(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    importCameras() {
      this.$store
        .dispatch('import_genetec_camera')
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.import', 'common.success,,1']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    save() {
      let item = Object.assign({}, this.state.items);
      delete item.configured;

      this.state.loading = true;
      this.$store
        .dispatch('check_genetec_config', item)
        .then((v) => {
          this.state.loading = false;
          if (!v.success) {
            this.$notify({
              duration: 0,
              message: this.$createElement('message-box', { props: { e: this.$tfo('config_error') + '.' } })
            });
            return false;
          } else {
            this.$notify({
              type: 'success',
              message: this.$tf(['common.check', 'common.config,,1', 'common.success,,2'])
            });
            return this.$store.dispatch('create_genetec_config', item).then((v) => {
              this.$notify({
                type: 'success',
                message: this.$tf(['common.set,,1', 'common.config,,1', 'common.success,,2'])
              });
              return this.$store.dispatch('get_genetec_config');
            });
          }
        })
        .catch((e) => {
          this.state.loading = false;
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    async fetchLicenseStatusIfRequired() {
      if (!this.hasLicense) {
        await this.$store.dispatch('getLicenseStatus');
      }
    },
    handleActionError(e) {
      this.$notify({
        duration: 0,
        message: this.$createElement('message-box', { props: { e } })
      });
    }
  }
};

const GenetecLicenseName = 'sec-genetec';
const GenetecFallbackLicense = { value: false };

function findGenetecLicense(license) {
  const limit = license && license.limits.find(({ name }) => name === GenetecLicenseName);
  return limit || GenetecFallbackLicense;
}
</script>

<style lang="stylus">
.genetec-settings {
  .genetec-overlay {
    background-color: rgba(47, 48, 53, 0.85)
    position: absolute;
    height: 100%;
    width: calc(100% - 2rem);
    z-index: 20;
    font-size: 2.5rem;

    .license_not_found {
      margin-top: 11rem;
    }
    .contact_admin_imv {
      margin-top: 2rem;
    }
  }
}
</style>
